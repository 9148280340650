import {css} from '@emotion/react'
import PropTypes from 'prop-types'
import { LogOut, useTheme } from '@findep/microfronts-core'
import { IconButton } from '@material-ui/core'
import { ExitToApp as ExitToAppIcon } from '@material-ui/icons'

function LogoutButton({ authClientId, ...props }) {
  const { palette } = useTheme();
  return (
    <LogOut clientId={`${authClientId}.apps.googleusercontent.com`}>
      {(logout) => (
        <IconButton onClick={logout} aria-label="Logout">
          <ExitToAppIcon
            css={css`
              color: ${palette.primary.contrastText};
            `}
            {...props}
          />
        </IconButton>
      )}
    </LogOut>
  );
}

LogoutButton.propTypes = {
  authClientId: PropTypes.string
};

LogoutButton.defaultProps = {
  authClientId: ''
}

export default LogoutButton
